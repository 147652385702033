<template>
  <div class="body-1">
    <v-card v-if="loadedUser != undefined">
      <v-card-title>
        {{loadedUser.lastName}}, {{loadedUser.firstName}}&nbsp;
        <a
          :href="stripeCustomerUrl"
          target="_blank"
          rel="noopener"
        >
          <v-icon>mdi-credit-card</v-icon>
        </a>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="loadedUser.firstName" label="First Name" required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="loadedUser.lastName" label="Last Name" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="loadedUser.emailAddress" label="Email Address" required></v-text-field>
            <v-text-field v-model="loadedUser.phoneNumber" label="Phone Number" required></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="loadedUser.emailConfirmed" label="Email Confirmed"></v-checkbox>
            <v-checkbox v-model="loadedUser.phoneNumberConfirmed" label="Phone Confirmed"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="loadedUser.address1" label="Address 1" required></v-text-field>
            <v-text-field v-model="loadedUser.address2" label="Address 2"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="loadedUser.city" label="City" required></v-text-field>
            <v-text-field v-model="loadedUser.state" label="State" required></v-text-field>
            <v-text-field v-model="loadedUser.zipCode" placeholder="Zip Code" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-checkbox v-model="loadedUser.isAdmin" label="Administrator"></v-checkbox>
            <v-checkbox v-model="loadedUser.isRestricted" label="Restricted"></v-checkbox>
            <v-checkbox v-model="loadedUser.isDisabled" label="Disabled"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              :items="subscriptionStatuses"
              v-model="loadedUser.subscriptionStatus"
              item-text="text"
              item-value="value"
              label="Subscription Type"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="loadedUser.connectionLimit"
              type="number"
              label="Connection Limit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Token-based Access</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>If you would like to use LiveCad in an unattended environment, you can use token-based access on the URL to authenticate yourself.</p>
            <p>
              Append
              <code>?accessKey={{loadedUser.externalAccessToken}}</code> to any LiveCad url to set up token-based access.
            </p>
            <p>
              Example:
              <code>https://livecad.us/show/config/OH-25-120?accessKey={{loadedUser.externalAccessToken}}</code>
            </p>
          </v-card-text>
        </v-card>
        <div v-if="errorSavingUser != undefined">
          <br />
          <v-alert type="error">{{errorSavingUser}}</v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :disabled="isSavingUser" color="primary">Save</v-btn>
        <v-btn @click="cancel" :disabled="isSavingUser" text>Cancel</v-btn>
        <v-btn @click="resetPassword" :disabled="isSavingUser" text>Reset Password</v-btn>
        <v-btn @click="deleteUser" :disabled="isSavingUser" text>Delete User</v-btn>
      </v-card-actions>
    </v-card>
    <p v-else>Loading...</p>

    <v-dialog v-model="resetPasswordModal" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Reset Password</v-card-title>
        <v-card-text>
          <p>This utility will reset the password of the user. No notification will be sent to the user.</p>
          <v-text-field type="password" v-model="resetPasswordValue" label="New Password"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelResetPassword">Cancel</v-btn>
          <v-btn @click="confirmResetPassword">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteConfirmation" max-width="500px" v-if="loadedUser != undefined">
      <v-card>
        <v-card-title>Confirm delete?</v-card-title>
        <v-card-text>Are you sure you want to delete {{loadedUser.emailAddress}}?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="confirmDeleteUser()">Delete</v-btn>
          <v-btn @click="cancelDeleteUser()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      resetPasswordModal: false,
      resetPasswordValue: "",
      subscriptionStatuses: [
        { text: "Subscription Required", value: 0 },
        { text: "Lifetime", value: 1 },
        { text: "Complimentary", value: 2 },
        { text: "Offline Billing", value: 3 },
        { text: "Testing", value: 4 }
      ],
      showDeleteConfirmation: false
    };
  },
  computed: {
    ...mapState({
      loadedUser: state => state.admin.loadedUser,
      isSavingUser: state => state.admin.isSavingUser,
      errorSavingUser: state => state.admin.errorSavingUser,
      forceRedirect: state => state.admin.hasSavedUserRedirect
    }),
    stripeCustomerUrl() {
      return `https://dashboard.stripe.com/customers/${this.loadedUser.stripeCustomerId}`;
    },
    stripeSubscriptionUrl() {
      return `https://dashboard.stripe.com/subscriptions/${this.loadedUser.stripeSubscriptionId}`;
    }
  },
  methods: {
    ...mapActions([
      "loadAdminUser",
      "saveAdminUser",
      "adminResetPassword",
      "adminDeleteUser"
    ]),
    save() {
      this.saveAdminUser({
        userId: this.$route.params.userId,
        loadedUser: this.loadedUser
      });
    },
    cancel() {
      this.$router.push({ name: "adminUserList" });
    },
    resetPassword() {
      this.resetPasswordValue = "";
      this.resetPasswordModal = true;
    },
    confirmResetPassword() {
      this.adminResetPassword({
        userId: this.$route.params.userId,
        newPassword: this.resetPasswordValue
      });
      this.resetPasswordModal = false;
    },
    cancelResetPassword() {
      this.resetPasswordModal = false;
    },
    deleteUser() {
      this.showDeleteConfirmation = true;
    },
    confirmDeleteUser() {
      this.adminDeleteUser({ userId: this.$route.params.userId });
      this.showDeleteConfirmation = false;
    },
    cancelDeleteUser() {
      this.showDeleteConfirmation = false;
    }
  },
  created() {
    this.loadAdminUser({ userId: this.$route.params.userId });
  },
  watch: {
    forceRedirect() {
      this.$router.push({ name: "adminUserList" });
    }
  }
};
</script>

