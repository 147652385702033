<template>
  <div class="body-1">
    <v-card>
      <v-card-title>Admin Menu</v-card-title>

      <v-card-text>
        <v-btn :to="{ name: 'adminConfigurationList' }">Configuration List</v-btn>
        <v-btn :to="{ name: 'adminConfigurationList2' }">Configuration List NEW!</v-btn>
        <v-btn :to="{ name: 'adminUserList' }">User List</v-btn>
        <v-btn :to="{ name: 'adminUserList2' }">User List NEW!</v-btn>
        <v-btn :to="{ name: 'adminAgencyList' }">Agency List</v-btn>
        <v-btn @click="postTranslationVersion">Reset Translation Version</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
export default {
  computed: mapState({}),
  methods: {
    ...mapActions([]),
    postTranslationVersion() {
      const url = "/api/translation/version";
      const token = localStorage.getItem("access_token");
      console.log("token", token);

      axios.post(url, null,
        {
          headers: { Authorization: "bearer " + token },
        })
        .then(() => {
          alert("Translation version updated.");
        })
        .catch(() => {
          // Handle any errors from the server
          alert("There was an error updating translation table version.");
        });
    }
  }
};
</script>
