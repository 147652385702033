<template>
  <div id="container">
    <div
      v-if="isLoadingConfigurationList || isLoadingInitialSync"
      class="text-center"
    >
      <h1>LiveCad is loading...</h1>
    </div>
    <div v-else-if="isLoadingSubscription">
      <h1>Checking subscription status...</h1>
    </div>
    <div v-else-if="!subscription.isActive">
      <h1>
        You do not have a current LiveCad subscription.
        <router-link :to="{ name: 'account' }"
          >Click here to review</router-link
        >
      </h1>
    </div>
    <div v-else-if="connectedCountExceeded">
      <h1>
        The number of current connections has been exceeded. This means that
        there are too many other LiveCad windows open using this account. You
        will need to close the other windows before trying to view LiveCad here.
      </h1>
    </div>
    <div v-else>
      <ConfigurationHeader
        :configurationName="configuration.configurationName"
        :notificationSound="notificationSound"
        @onNotificationSoundChange="onNotificationSoundChange"
      />
      <ConfigurationBody />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { setInterval, clearInterval } from "timers";
import moment from "moment";

export default {
  data() {
    return {
      secondsLeftUntilReconnection: 0,
      currentTime: new Date(),
      blinkInterval: undefined,
      syncInterval: undefined,
      pingInterval: undefined,
      refreshInterval: undefined,
      notificationSound: 0, // default to off
      alarms: [
        new Audio(`/sounds/alarm1.mp3`),
        new Audio(`/sounds/alarm2.mp3`),
        new Audio(`/sounds/alarm3.mp3`),
      ],
    };
  },
  computed: mapState({
    configuration: (state) => state.selectedConfiguration,
    selectedConfigurationId: (state) => state.selectedConfigurationId,
    isLoadingConfigurationList: (state) => state.isLoadingConfigurationList,
    isLoadingInitialSync: (state) => state.isLoadingInitialSync,
    blink: (state) => state.blink,
    syncHubConnected: (state) => state.syncHubConnected,
    connectedCountExceeded: (state) => state.connectedCountExceeded,
    subscription: (state) => state.subscription,
  }),
  methods: {
    ...mapActions([
      "loadConfigurationList",
      "loadSubscription",
      "setLocalConfigurationId",
      "stopBlink",
    ]),
    configureSync() {
      if (this.syncHubConnected) {
        this.$syncHub.subscribe(this.$route.params.configId);

        var resyncRequest = this.$syncHub.resync.bind(
          this,
          this.$route.params.configId
        );

        var pingRequest = this.$syncHub.ping.bind(
          this,
          this.$route.params.configId
        );

        var pingInterval = 1 * 60 * 1000;
        var resyncInterval = 15 * 60 * 1000; // 15 minutely
        clearInterval(this.syncInterval);
        clearInterval(this.pingInterval);

        this.syncInterval = setInterval(resyncRequest, resyncInterval);
        this.pingInterval = setInterval(pingRequest, pingInterval);

        this.$syncHub.resync(this.$route.params.configId);
        this.$syncHub.ping(this.$route.params.configId);
      } else {
        clearInterval(this.syncInterval);
      }
    },
    onNotificationSoundChange(value) {
      this.stopSound();
      this.notificationSound = value;
      this.setNotificationSoundQueryString(value);

      this.playSound();
    },
    setNotificationSoundQueryString(value) {
      let params = new URLSearchParams(window.location.search);
      params.set("alertSound", value);
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?${params.toString()}`
      );
    },
    refresh() {
      location.reload();
    },
    playSound() {
      if (this.notificationSound === 0) return;
      console.log("Playing sound");

      this.alarms[this.notificationSound - 1].play();
      const duration = 5000;

      setTimeout(() => {
        this.stopSound();
      }, duration);
    },
    stopSound() {
      // are any alarms playing?
      const isPlaying = this.alarms.some((alarm) => !alarm.paused);
      
      if (isPlaying) {
        this.alarms.forEach((alarm) => {
          alarm.pause();
          alarm.currentTime = 0;
        });
      }
    },
  },
  components: {
    ConfigurationHeader: () => import("./ConfigurationHeader"),
    ConfigurationBody: () => import("./ConfigurationBody"),
  },
  created() {
    this.loadSubscription();
    this.setLocalConfigurationId(this.$route.params.configId);
    this.loadConfigurationList();
    if (this.syncHubConnected) {
      this.configureSync();
    } else {
      this.$syncHub.start();
    }

    let secondsForRefresh = 30 * 60 * 1000; // 30 minutes
    this.refreshInterval = setTimeout(this.refresh, secondsForRefresh);

    let secondsForSubRefresh = 1 * 60 * 1000; // 1 minute
    this.subRefreshInterval = setInterval(
      this.loadSubscription,
      secondsForSubRefresh
    );

    // check query string for notification sound
    let queryString = window.location.search;
    let params = new URLSearchParams(queryString);
    let notificationSound = params.get("alertSound");
    if (notificationSound) {
      // if notificationSound isn't number
      if (isNaN(notificationSound)) {
        notificationSound = 0;
      } else {
        const notificationSoundInt = parseInt(notificationSound);
        if (notificationSoundInt > 3) {
          notificationSound = 0;
        } else {
          this.notificationSound = notificationSoundInt;
        }
      }
    }

    window.addEventListener("keyup", (e) => {
      // if escape is pressed, stopSound
      console.log(e.key);
      if (e.key === "Escape") {
        this.stopSound();
      }
    });

    window.addEventListener("keypress", (e) => {
      switch (e.key) {
        case "`":
        case "0":
          this.notificationSound = 0;
          break;
        case "1":
          this.notificationSound = 1;
          break;
        case "2":
          this.notificationSound = 2;
          break;
        case "3":
          this.notificationSound = 3;
          break;
      }

      this.setNotificationSoundQueryString(this.notificationSound);
    });
  },
  beforeDestroy() {
    this.$syncHub.unsubscribe(this.selectedConfigurationId);
    clearTimeout(this.refreshInterval);
    clearInterval(this.syncInterval);
  },
  watch: {
    configuration() {
      if (this.isLoadingConfigurationList) return;
      if (this.configuration === undefined)
        this.$router.push({ name: "configurationList" });
    },
    isLoadingConfigurationList() {
      if (this.configuration === undefined)
        this.$router.push({ name: "configurationList" });
    },
    syncHubConnected: function () {
      this.configureSync();
    },
    blink: function () {
      // this needs to be added, removed up to duration...
      // this.$parent.$el.parentElement.className = "blink";

      if (this.blink && !this.blinkInterval) {
        this.playSound();
        this.blinkStop = moment().add(
          this.configuration.configurationDuration,
          "seconds"
        );

        this.blinkInterval = setInterval(() => {
          // blink on?
          if (this.$parent.$el.parentElement.className === "blink") {
            this.$parent.$el.parentElement.className = "";
          } else {
            this.$parent.$el.parentElement.className = "blink";
          }

          if (moment().diff(this.blinkStop, "seconds") >= 0) {
            this.$parent.$el.parentElement.className = "";
            this.blinkStop = undefined;
            clearInterval(this.blinkInterval);
            this.blinkInterval = undefined;

            this.stopBlink();
          }
        }, 1000);
      }
    },
  },
};
</script>

<style>
@import "../assets/styles/livecad_dashboard.css";
@import "../assets/styles/livecad_agencytags.css";
@import "../assets/styles/livecad_truckstatus.css";
</style>
