<template>
  <div id="container" v-if="isLoadingAccount">
    <p>Loading</p>
  </div>
  <div id="container" v-else>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Account Information</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout align-space-between justify-space-around row fill-height>
          <v-flex md5>
            <v-text-field
              v-model="account.firstName"
              label="First Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.lastName"
              label="Last Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.departmentName"
              label="Department Name"
            ></v-text-field>
          </v-flex>
          <v-flex md5>
            <v-text-field
              v-model="account.address1"
              label="Address 1"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.address2"
              label="Address 2"
            ></v-text-field>
            <v-text-field
              v-model="account.city"
              label="City"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.state"
              label="State"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.zipCode"
              label="Zip Code"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-alert type="error" v-if="accountLoadError">{{
          accountLoadError
        }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="save">Save</v-btn>
        <v-btn text @click="reset">Reset</v-btn>
      </v-card-actions>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Subscription</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-if="isLoadingSubscription || isUpdatingAccount">
          <p>Loading...</p>
        </div>
        <div v-else-if="currentSubscription">
          <p v-if="subscription.subscriptionStatus === 0">
            You have a current subscription.
            <span v-if="!subscription.cancelsOn && !isSpecialExpirationDate"
              >Your subscription will automatically renew on
              {{ subscriptionEndDate | asLocalTime }}</span
            >
          </p>
          <p v-else-if="subscription.subscriptionStatus === 1">
            You have a lifetime subscription.
          </p>
          <p v-else-if="subscription.subscriptionStatus === 2">
            You have a complimentary subscription.
          </p>
          <p v-else-if="subscription.subscriptionStatus === 3">
            You have an offline subscription.
          </p>
          <p v-else-if="subscription.subscriptionStatus === 4">
            You have a testing subscription.
          </p>

          <div v-if="subscription.refundableSubscriptionType">
            <p v-if="subscription.cancelsOn">
              Your subscription will automatically cancel on
              {{ subscription.cancelsOn | asLocalTime }}
            </p>
            <p v-else-if="subscription.eligibleForFullRefund">
              You can cancel today and receive a FULL REFUND.
            </p>
            <p v-else-if="subscription.eligibleForProratedRefund">
              You can cancel today and receive a prorated refund.
            </p>
            <p v-else>
              You can cancel today, and use LiveCad through the end of your
              term.
            </p>
          </div>
        </div>
        <div v-else-if="newSubscription">
          <p>
            We're currently waiting for your subscription information to go
            through. If you have issues, please contact
            <a href="mailto:questions@livecad.us">LiveCad support</a>
          </p>
        </div>
        <div v-else>
          <p>
            You are not currently subscribed to a LiveCad plan. Sign up today
            for $15/year!
          </p>
          <p v-if="subscription.subscriptionExpirationDate && !isSpecialExpirationDate">
            Your previous LiveCad plan expired on
            {{ subscription.subscriptionExpirationDate | asLocalTime }} and did
            not renew automatically.
          </p>
          <v-alert type="error" v-if="sourceError">{{ sourceError }}</v-alert>
          <p>
            <v-stripe-card
              ref="stripeCard"
              v-model="source"
              :api-key="stripe"
              create="source"
              clearable
            ></v-stripe-card>
            <v-btn
              class="success"
              @click="register"
              :disabled="isCreatingAccount || ccIsNotGood"
            >
              <span v-if="isCreatingAccount">Creating...</span>
              <span v-if="!isCreatingAccount">Register</span>
            </v-btn>
          </p>
          <p>
            If you recently subscribed to LiveCad, and believe this is an error,
            contact
            <a href="mailto:questions@livecad.us">LiveCad support</a>.
          </p>
        </div>
      </v-card-text>
      <v-card-actions
        v-if="
          currentSubscription && !isUpdatingAccount && !subscription.cancelsOn &&
          subscription.subscriptionStatus !== 1 && subscription.subscriptionStatus !== 2
        "
      >
        <v-btn
          color="red"
          @click="cancelSubscription(0)"
          v-if="subscription.eligibleForFullRefund"
          >Cancel Subscription Now (Full Refund)</v-btn
        >
        <v-btn
          color="red"
          @click="cancelSubscription(1)"
          v-if="
            !subscription.eligibleForFullRefund &&
            subscription.eligibleForProratedRefund
          "
          >Cancel Subscription Now (Prorated Refund)</v-btn
        >
        <v-btn
          color="red"
          @click="cancelSubscription(2)"
          >Cancel Subscription at End of Term</v-btn
        >
      </v-card-actions>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Token-based Access</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>
          If you would like to use LiveCad in an unattended environment, you can
          use token-based access on the URL to authenticate yourself.
        </p>
        <p>
          Append
          <code>?accessKey={{ account.externalAccessToken }}</code> to any
          LiveCad url to set up token-based access.
        </p>
        <p>
          Example:
          <code
            >https://livecad.us/show/config/OH-25-120?accessKey={{
              account.externalAccessToken
            }}</code
          >
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      now: moment(),
      source: null,
      sourceError: "",
      isCreatingAccount: false,
      isUpdatingAccount: false,
      newSubscription: false,
    };
  },
  computed: {
    ...mapState({
      isLoadingAccount: (state) => state.isLoadingAccount,
      isLoadingSubscription: (state) => state.isLoadingSubscription,
      accountLoadError: (state) => state.accountLoadError,
      account: (state) => state.account,
      subscription: (state) => state.subscription,
      subscriptionEndDate: (state) =>
        moment(state.subscription.subscriptionExpirationDate),
      stripe: (state) => state.stripePublishableKey,
      host: (state) => state.host,
      loggedInAs: (state) => state.loggedInAs,
    }),
    currentSubscription() {
      return this.subscription.isActive;
    },
    ccIsNotGood() {
      return this.source === null;
    },
    isSpecialExpirationDate() {
      return this.subscription.subscriptionExpirationDate === "2099-12-31";
    },
  },
  methods: {
    ...mapActions(["loadAccount", "saveAccount", "loadSubscription"]),
    save() {
      this.saveAccount(this.account);
    },
    reset() {
      this.loadAccount();
    },
    async register() {
      var token = localStorage.getItem("access_token");

      this.isCreatingAccount = true;
      this.sourceError = "";

      var url = "/account/reactivate";
      var d = {
        source: this.source !== null ? this.source.id : null,
      };

      try {
        await axios.post(url, d, {
          headers: { Authorization: "bearer " + token },
        });
        this.loadSubscription();
      } catch (e) {
        this.sourceError = e.response.data;
        this.$refs.stripeCard.clearableCallback();
      } finally {
        this.isCreatingAccount = false;
      }
    },
    async cancelSubscription(cancellationType) {
      this.isUpdatingAccount = true;
      var token = localStorage.getItem("access_token");
      var url = "/api/subscription";

      try {
        await axios.delete(url, {
          headers: { Authorization: "bearer " + token },
          params: { cancellationType: cancellationType },
        });
        await this.loadSubscription();
      } catch (e) {
        this.sourceError = e.response.data;
      } finally {
        this.isUpdatingAccount = false;
      }
    },
  },
  created() {
    this.loadAccount();
    this.loadSubscription();

    var ns = sessionStorage.getItem("NEW_SUBSCRIPTION");
    if (ns) this.newSubscription = true;
  },
  filters: {
    asLocalTime(value) {
      var momentDate = moment(value);
      var formattedTime = momentDate.format("dddd, MMMM Do YYYY");
      return formattedTime;
    },
  },
};
</script>
