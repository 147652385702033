<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md8>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Login to LiveCad</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
        <p style="background-color:yellow;padding:5px;font-weight:bolder">
          Please note: If you were registered on the old LiveCad system, your credentials will not work here on the new version. You will need to subscribe <a href="https://livecad.us/register">HERE</a>
        </p>
          <v-form>
            <v-text-field
              v-model="emailAddress"
              prepend-icon="mdi-person"
              name="email"
              label="Email Address"
              type="text"
              v-on:keyup.enter="onSubmit"
            ></v-text-field>
            <v-text-field
              v-model="password"
              prepend-icon="mdi-lock"
              name="password"
              label="Password"
              id="password"
              type="password"
              v-on:keyup.enter="onSubmit"
            ></v-text-field>
          </v-form>
          <v-alert type="error" :value="loginError != ''">{{loginError}}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :to="{name: 'forgotPassword'}">Forgot Password?</v-btn>
          <v-btn :disabled="isLoggingIn" color="primary" @click="onSubmit">{{loginButton}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      emailAddress: "",
      password: ""
    };
  },
  computed: mapState({
    loginError: state => state.loginError,
    isLoggingIn: state => state.isLoggingIn,
    loginButton() {
      return this.isLoggingIn ? "Logging in..." : "Login";
    }
  }),
  methods: {
    ...mapActions(["login"]),
    onSubmit() {
      this.login({
        emailAddress: this.emailAddress,
        password: this.password,
        redirect: this.$route.query.redirect
      });
    },
    onResult() {}
  }
};
</script>